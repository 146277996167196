import React, { FC, ReactNode } from "react";
import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: "Stays" | "Experiences" | "Cars" | "Flights";
  currentTab: SearchTab;
  rightImage?: string;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  currentPage,
  currentTab,
}) => {
  const { t, i18n } = useTranslation("home");
  const isEnglish = i18n.language === "en";
  return (
    <div
      className={`nc-SectionHeroArchivePage flex flex-col relative ${className}`}
      data-nc-id="SectionHeroArchivePage"
    >
      <div className="flex  flex-col lg:flex-row lg:items-center">
        <div className=" text-white  flex flex-col items-start space-y-6 lg:space-y-10 md::pt-14 md:pb-14 lg:pb-52 xl:pb-52  xl:mr-0">
          {/* <div className="text-2xl">
            {t("PAGESUBTITLE", "LEGAL CAR RENTAL COMPANY")}{" "}
          </div> */}
          <div className="flex flex-wrap">
            <h1
              className={`font-bold ${
                isEnglish
                  ? "text-3xl md:text-4xl xl:text-5xl"
                  : "text-4xl md:text-5xl xl:text-7xl"
              } leading-[110%]`}
            >
              {t("PAGETITLE", "租車")}
            </h1>
            <h3
              className={`pl-5 font-bold ${
                isEnglish
                  ? "text-3xl md:text-4xl xl:text-5xl pl-0"
                  : "text-4xl md:text-5xl xl:text-7xl"
              } leading-[110%]`}
            >
              {t("PAGETITLE4", "比養車更實惠！")}
            </h3>
          </div>

          <h2
            className={`font-bold ${
              isEnglish
                ? "text-2xl md:text-3xl xl:text-4xl"
                : "text-4xl md:text-5xl xl:text-7xl"
            } leading-[110%] xl:!mt-10`}
          >
            {t("PAGETITLE2", "係一種生活!")}
          </h2>
          <h3
            className={`text-xl md:text-2xl xl:text-3xl leading-[110%] xl:!mt-10`}
          >
            {t(
              "PAGETITLE3",
              "租金包括保險牌費，自然損壞維修、保養費用，不再擔心車輛折舊損失！"
            )}
          </h3>
          <div className="!my-10 flex flex-col md:flex-row  md:gap-4 mx-auto xl:mx-0">
            <ButtonPrimary
              targetBlank
              href={"/rentalprice.pdf"}
              className="!px-10"
            >
              {t("PRICELIST", "租車價目表")}
            </ButtonPrimary>
            <ButtonPrimary
              href={"/yacht-rental"}
              className="!my-5 md:!my-0 !px-10"
            >
              {t("YACHTRENT", { defaultValue: "遊艇租賃" })}
            </ButtonPrimary>
          </div>

          <div className="rounded relative ">
            <span className="sm:inline">{t("HOLIDAYNOTICE")}</span>
          </div>
        </div>
      </div>

      <div className="lg:flow-root w-full mt-6 lg:mt-15">
        <div className="z-10 lg:-mt-40 xl:-mt-56 w-full">
          <HeroSearchForm currentPage={currentPage} currentTab={currentTab} />
        </div>
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;
